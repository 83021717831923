@import 'theme';

.blurFixedWrapper {
	filter: blur(7.5px);
	pointer-events: none;
	user-select: none;
	position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 20rem auto;
	color: var(--neo-color-global-content-neutral-intense);
	&:before {
		pointer-events: none;
		user-select: none;
		content: '';
		position: absolute;
		width: 120%;
		height: 120%;
		top: -10%;
		left: -10%;
		background: black;
		opacity: 0.75;
		z-index: 1;
	}

	@media (max-width: 80rem) {
		grid-template-columns: auto;
	}

	nav {
		background-color: var(--neo-color-web-app-surface-navigation);
		display: flex;
		padding: 1rem;
		align-content: flex-start;
		flex-wrap: wrap;
		gap: 2.75rem;

		@media (max-width: 80rem) {
			display: none;
		}

		> header {
			width: 100%;

			.hideButton {
				display: flex;
				align-items: center;
				justify-self: flex-end;
				justify-content: center;
				width: 2rem;
				height: 2rem;
				padding: 0.25rem;
				border-radius: 0.5rem;
				background-color: var(--neo-color-global-background-neutral-soft-default);
			}
		}

		> ul {
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			gap: 0.25rem;
			margin: 0;

			> li {
				width: 100%;
				color: var(--neo-color-global-content-neutral-moderate);
				list-style-type: none;
				line-height: 1.5rem;
				font-size: 1rem;
				font-weight: 700;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.linkWrapper {
					width: 100%;
					display: flex;
					gap: 0.5rem;
					padding: 0.5rem 0.75rem;
					border-radius: 0.5rem;
					height: 3rem;
					align-items: center;
				}

				.expandActive {
					margin-left: auto;
					transform: scaleY(-1);
				}

				.expandInactive {
					margin-left: auto;
				}

				&.active {
					.linkWrapper {
						color: var(--neo-color-global-content-primary-intense);
					}
					&.selected {
						.linkWrapper {
							background-color: var(--neo-color-global-background-primary-soft-active);
						}
					}
				}

				> ul {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					gap: 0.25rem;

					> li {
						display: flex;
						align-items: center;
						width: 100%;
						list-style-type: none;
						height: 3rem;
						padding: 0.5rem 0.75rem;
						font-weight: 400;
					}
				}
			}
		}
	}

	main {
		display: grid;
		grid-template-rows: auto 1fr;
		background-color: var(--neo-color-web-app-surface-app);

		> header {
			padding: 1rem 5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 80rem) {
				background-color: #191919;
				justify-content: flex-start;
				gap: 1rem;
				padding: 1rem;
			}

			.mobileNavButton {
				display: none;
				/*
				height: 100%;

				@media (max-width: 80rem) {

					display: none;
					flex-direction: column;
					justify-content: center;
					gap: 0.25rem;
				}

				span {
					content: '';
					background-color: #eee;
					width: 1.5rem;
					height: 0.125rem;
				}
				*/
			}

			.mobileLogo {
				display: none;
				/*
				@media (max-width: 80rem) {
					display: none;
					height: 2rem;
				}
				*/
			}

			p {
				font-weight: 700;
				color: var(--neo-color-global-content-neutral-moderate);
				font-size: 0.875rem;
				margin: 0;

				@media (max-width: 80rem) {
					display: none;
				}
			}

			.buttonWrapper {
				display: flex;
				gap: 1rem;

				@media (max-width: 80rem) {
					margin-left: auto;
				}

				@media (max-width: 40rem) {
					display: none;
				}

				.mockButton {
					display: flex;
					align-items: center;
					border-radius: 0.25rem;
					font-size: 1rem;
					font-weight: 700;
					line-height: 1.5rem;
					padding: 0.5rem 1rem;
					text-align: left;
					gap: 0.5rem;

					&:nth-child(1) {
						background-color: var(--neo-color-global-background-neutral-soft-default);

						@media (max-width: 80rem) {
							background-color: #232323;
							color: var(--neo-color-web-app-component-area-switch-content-default);
						}
					}

					&:nth-child(2) {
						background-color: var(--neo-color-web-app-component-area-switch-background-default);
						color: var(--neo-color-web-app-component-area-switch-content-default);

						@media (max-width: 80rem) {
							background-color: var(--neo-color-web-app-component-area-switch-content-default);
							color: #232323;

							svg path {
								fill: #232323;
							}
						}
					}
				}
			}
		}
		.content {
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
			padding: 0 5rem;

			@media (max-width: 80rem) {
				padding: 5rem 2.5rem 0 2.5rem;
			}

			.greeting {
				display: flex;
				gap: 1rem;
				margin-top: 2rem;

				svg {
					margin: 0;
				}

				.greetingTextWrapper {
					h2 {
						color: var(--neo-color-global-content-neutral-intense);
						font-feature-settings: 'calt' off;
						font-family: $font-din-next;
						font-size: 2.3625rem;
						font-style: normal;
						font-weight: 300;
						line-height: 2.25rem;
						margin: 0;

						& em {
							display: block;
							font-style: normal;
							font-family: $font-px-grotesk;
							font-size: 2rem;
							font-weight: 700;
							line-height: 2.5rem;
						}
					}

					span {
						font-feature-settings:
							'case' on,
							'ss08' on;
						color: var(--neo-color-global-content-neutral-moderate);
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: 1.5rem;
					}
				}
			}

			.accountManagementOverview {
				display: flex;
				flex-direction: column;
				gap: 2.5rem;

				section {
					display: flex;
					gap: 1rem;
					flex-wrap: wrap;

					h2 {
						font-size: 1.5rem;
						line-height: 2rem;
						color: var(--neo-color-global-content-neutral-intense);
						font-weight: 700;
						margin: 0;
						width: 100%;
					}

					.tileWrapper {
						display: grid;
						gap: 1rem;
						grid-template-columns: repeat(auto-fill, minmax(22.5rem, 1fr));
						width: 100%;

						@media (max-width: 40rem) {
							grid-template-columns: repeat(auto-fill, minmax(16.5rem, 1fr));
						}

						.tile {
							display: grid;
							grid-template-columns: 1fr auto;
							background-color: var(--neo-color-web-app-component-card-background-default);
							border: 0.125rem solid var(--neo-color-global-border-neutral-soft-default);
							border-radius: 0.5rem;
							color: var(--neo-color-global-content-neutral-moderate);
							padding: 1.5rem 1rem;
							gap: 0.5rem;
							align-items: flex-start;
							align-content: flex-start;

							h3 {
								margin: 0;
								align-content: center;
								color: var(--neo-color-global-content-link-default);
								text-decoration: none;
								font-size: 1.25rem;
								font-weight: 700;
								line-height: 1.75rem;
								hyphens: auto;
							}

							div {
								svg path {
									fill: var(--neo-color-global-content-neutral-moderate);
								}
							}

							p {
								margin: 0;
								grid-column: 1 / -1;
							}
						}
					}
				}
			}
		}
	}
}

.blurFixedWrapperNeoPbx {
	filter: blur(7.5px);
	pointer-events: none;
	user-select: none;
	position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 20rem auto;
	color: var(--neo-color-global-content-neutral-intense);
	&:before {
		pointer-events: none;
		user-select: none;
		content: '';
		position: absolute;
		width: 120%;
		height: 120%;
		top: -10%;
		left: -10%;
		background: black;
		opacity: 0.75;
		z-index: 1;
	}

	@media (max-width: 80rem) {
		grid-template-columns: auto;
	}

	nav {
		background-color: var(--neo-color-web-app-surface-navigation);
		display: flex;
		padding: 1rem;
		align-content: flex-start;
		flex-wrap: wrap;
		gap: 2.75rem;

		@media (max-width: 80rem) {
			display: none;
		}

		> header {
			width: 100%;

			.hideButton {
				display: flex;
				align-items: center;
				justify-self: flex-end;
				justify-content: center;
				width: 2rem;
				height: 2rem;
				padding: 0.25rem;
				border-radius: 0.5rem;
				background-color: var(--neo-color-global-background-neutral-soft-default);
			}
		}

		> ul {
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			gap: 0.25rem;
			margin: 0;

			> li {
				width: 100%;
				color: var(--neo-color-global-content-neutral-moderate);
				list-style-type: none;
				line-height: 1.5rem;
				font-size: 1rem;
				font-weight: 700;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.linkWrapper {
					width: 100%;
					display: flex;
					gap: 0.5rem;
					padding: 0.5rem 0.75rem;
					border-radius: 0.5rem;
					height: 3rem;
					align-items: center;
				}

				.expandActive {
					margin-left: auto;
					transform: scaleY(-1);
				}

				.expandInactive {
					margin-left: auto;
				}

				&.active {
					.linkWrapper {
						color: var(--neo-color-global-content-primary-intense);
					}
					&.selected {
						.linkWrapper {
							background-color: var(--neo-color-global-background-primary-soft-active);
						}
					}
				}

				> ul {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					gap: 0.25rem;

					> li {
						display: flex;
						align-items: center;
						width: 100%;
						list-style-type: none;
						height: 3rem;
						padding: 0.5rem 0.75rem;
						font-weight: 400;
					}
				}
			}
		}
	}

	main {
		display: grid;
		grid-template-rows: auto 1fr;
		background-color: var(--neo-color-web-app-surface-app);

		> header {
			padding: 1rem 5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 72px;

			@media (max-width: 80rem) {
				background-color: #191919;
				justify-content: flex-start;
				gap: 1rem;
				padding: 1rem;
			}

			.mobileNavButton {
				display: none;
				/*
				height: 100%;

				@media (max-width: 80rem) {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 0.25rem;
				}

				span {
					content: '';
					background-color: #eee;
					width: 1.5rem;
					height: 0.125rem;
				}
				*/
			}

			.mobileLogo {
				display: none;
				/*
				@media (max-width: 80rem) {
					display: block;
					height: 2rem;
				}

 */
			}

			p {
				font-weight: 700;
				color: var(--neo-color-global-content-neutral-moderate);
				font-size: 0.875rem;
				margin: 0;

				@media (max-width: 80rem) {
					display: none;
				}
			}

			.buttonWrapper {
				display: flex;
				gap: 1rem;

				@media (max-width: 80rem) {
					margin-left: auto;
				}

				@media (max-width: 40rem) {
					display: none;
				}

				.mockButton {
					display: flex;
					align-items: center;
					border-radius: 0.25rem;
					font-size: 1rem;
					font-weight: 700;
					line-height: 1.5rem;
					padding: 0.5rem 1rem;
					text-align: left;
					gap: 0.5rem;

					&:nth-child(1) {
						background-color: var(--neo-color-global-background-neutral-soft-default);

						@media (max-width: 80rem) {
							background-color: #232323;
							color: var(--neo-color-web-app-component-area-switch-content-default);
						}
					}

					&:nth-child(2) {
						background-color: #deff00;
						color: #39401e;

						svg path {
							fill: #39401e;
						}
					}
				}

				svg {
					circle {
						fill: #a8c7a8;
					}

					text {
						fill: #163d39;
					}
				}
			}
		}
		.content {
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
			padding: 0 5rem;
			max-width: 105rem;

			@media (max-width: 80rem) {
				padding: 5rem 2.5rem 0 2.5rem;
			}

			.greeting {
				display: flex;
				gap: 1rem;
				margin-top: 2rem;

				svg {
					margin: 0;

					circle {
						fill: #a8c7a8;
					}

					text {
						fill: #163d39;
					}
				}

				.greetingTextWrapper {
					h2 {
						color: var(--neo-color-global-content-neutral-intense);
						font-feature-settings: 'calt' off;
						font-family: $font-din-next;
						font-size: 2.3625rem;
						font-style: normal;
						font-weight: 300;
						line-height: 2.25rem;
						margin: 0;

						& em {
							display: block;
							font-style: normal;
							font-family: $font-px-grotesk;
							font-size: 2rem;
							font-weight: 700;
							line-height: 2.5rem;
						}
					}

					span {
						font-feature-settings:
							'case' on,
							'ss08' on;
						color: var(--neo-color-global-content-neutral-moderate);
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: 1.5rem;
					}
				}
			}

			.widgetWrapper {
				display: grid;
				grid-template-columns: 2fr 2fr 1fr;
				gap: 1rem;

				@media (max-width: 98.75rem) {
					grid-template-columns: 1fr 1fr;
				}

				@media (max-width: 40rem) {
					grid-template-columns: 1fr;
				}

				.widget {
					background-color: var(--neo-color-web-app-component-card-background-default);
					border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
					padding: 1.5rem;
					border-radius: 0.5rem;
					display: flex;
					flex-direction: column;
					gap: 1rem;

					@media (max-width: 98.75rem) {
						&:nth-child(3) {
							grid-column: span 2;
						}
					}

					@media (max-width: 40rem) {
						&:nth-child(3) {
							grid-column: span 1;
						}
					}

					header {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 1rem;

						h2 {
							font-size: 1.5rem;
							font-weight: 700;
							line-height: 2rem;
							margin: 0;

							span {
								font-weight: 300;
							}
						}

						.buttonWrapper {
							display: flex;
							gap: 1rem;

							@media (max-width: 40rem) {
								display: none;
							}

							.mockButton {
								display: flex;
								align-items: center;
								border-radius: 0.25rem;
								font-size: 1rem;
								font-weight: 700;
								line-height: 1.5rem;
								padding: 0.75rem 1rem;
								text-align: left;
								gap: 0.5rem;
								background-color: var(--neo-color-global-background-neutral-soft-default);
							}
						}
					}

					.widgetContent {
						display: flex;
						gap: 2.5rem;

						.userVisual {
							position: relative;
							width: 7.5rem;

							svg {
								width: 100%;

								circle {
									stroke: var(--neo-color-global-background-neutral-soft-default);
									stroke-width: 0.75rem;
									fill: none;
								}
							}

							div {
								font-size: 1.5rem;
								font-weight: 700;
								left: 49px;
								line-height: 2rem;
								position: absolute;
								top: 44px;

								span {
									font-size: 0.875rem;
									line-height: 1rem;
									font-weight: 700;
								}
							}
						}

						.userDescription {
							display: flex;
							flex: 1;
							align-items: flex-start;
							flex-direction: column;
							justify-content: center;

							h2 {
								font-size: 1.125rem;
								font-weight: 700;
								line-height: 1.5rem;
								padding-bottom: 0.25rem;
								margin: 0;
							}

							p {
								color: var(--neo-color-global-content-neutral-moderate);
								font-size: 0.875rem;
								font-weight: 400;
								line-height: 1.2rem;
								margin: 0;
							}

							.mockButton {
								display: flex;
								align-items: center;
								border-radius: 0.25rem;
								font-size: 1rem;
								font-weight: 700;
								line-height: 1.5rem;
								padding: 0.75rem 1rem;
								text-align: left;
								gap: 0.5rem;
								background-color: #121212;
								color: var(--neo-color-global-content-primary-on-intense);
								margin-top: 0.5rem;
							}
						}

						.channelVisual {
							padding-top: 1.5rem;

							img {
								max-width: 7.5rem;
							}
						}

						.channelDescription {
							display: flex;
							flex-wrap: wrap;
							align-items: center;

							h2 {
								margin: 0;
								font-size: 1.25rem;
								font-weight: 700;
								margin: 0;
							}

							p {
								line-height: 1.5rem;
							}
						}

						.updateWrapper {
							position: relative;
							overflow: hidden;
							max-height: 11rem;
							display: flex;
							flex-direction: column;
							gap: 1rem;
							width: 100%;

							.updateEntry {
								position: relative;

								&:after {
									content: '';
									position: absolute;
									width: 100%;
									height: 1px;
									bottom: -0.5rem;
									left: 0;
									border-bottom: 1px solid var(--neo-color-global-border-neutral-moderate-default);
								}

								p {
									font-size: 0.75rem;
									font-weight: 300;
									line-height: 0.875rem;
									margin: 0;
								}

								strong {
									font-size: 0.875rem;
									font-weight: 400;
									margin: 0;
								}
							}

							.updateFadeout {
								position: absolute;
								width: 100%;
								height: 4rem;
								bottom: 0;
								left: 0;
								background: linear-gradient(
									0deg,
									rgba(255, 255, 255, 1) 0%,
									rgba(255, 255, 255, 0) 100%
								);
							}
						}
					}

					footer {
						&.userFooter {
							strong {
								width: 100%;
							}

							> div {
								display: flex;
								gap: 0.5rem;
								margin-top: 0.25rem;

								div {
									align-items: center;
									border-radius: 0.25rem;
									display: flex;
									font-size: 0.875rem;
									line-height: 1rem;
									padding: 0.375rem 0.5rem;

									span {
										border-radius: 50%;
										height: 0.5rem;
										margin-right: 0.25rem;
										width: 0.5rem;
									}

									&:nth-child(1) {
										span {
											background-color: var(--neo-color-global-chart-category-4-monochrome-default);
										}
									}

									&:nth-child(2) {
										span {
											background-color: var(--neo-color-global-chart-category-3-monochrome-default);
										}
									}

									&:nth-child(3) {
										span {
											background-color: var(--neo-color-global-chart-category-1-monochrome-default);
										}
									}
								}
							}
						}

						&.updateFooter {
							display: flex;
							align-items: center;
							gap: 0.25rem;
							padding: 1rem 1.5rem;
							margin: 0 -1.5rem -1.5rem -1.5rem;
							p {
								font-weight: 400;
								font-size: 1rem;
								margin: 0;
							}
						}
					}
				}
			}

			.accountManagementOverview {
				display: flex;
				flex-direction: column;
				gap: 2.5rem;

				section {
					display: flex;
					gap: 1rem;
					flex-wrap: wrap;

					h2 {
						font-size: 0.875rem;
						font-weight: 700;
						grid-area: header;
						line-height: 1rem;
						margin: 0;
						width: 100%;
					}

					.tileOuterWrapper {
						display: grid;
						grid-template-columns: 2fr 2fr 1fr;
						gap: 1rem;
						width: 100%;

						@media (max-width: 98.75rem) {
							grid-template-columns: 1fr;
						}

						.tileWrapper {
							grid-column: span 2;
							display: grid;
							//grid-template-columns: repeat(4, 1fr);
							grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

							gap: 1rem;
							width: 100%;

							.tile {
								display: grid;
								grid-template-columns: auto 1fr;
								background-color: var(--neo-color-web-app-component-card-background-default);
								border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
								border-radius: 0.5rem;
								color: var(--neo-color-global-content-neutral-moderate);
								padding: 1rem;
								gap: 0.5rem;
								align-items: center;

								h3 {
									margin: 0;
									align-content: flex-end;
									color: var(--neo-color-global-content-neutral-intense);
									text-decoration: none;
									font-size: 1rem;
									font-weight: 700;
									line-height: 1rem;
									hyphens: auto;
								}

								div {
									svg path {
										fill: var(--neo-color-global-content-neutral-moderate);
									}
								}

								p {
									margin: 0;
									grid-column: 1 / -1;
								}
							}
						}
					}
				}
			}
		}
	}
}
