.withBackground {
	background-color: var(--neo-color-web-app-surface-signup);
}
.cover {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
}

/* Makes sure that the blurred background is only shown after the first Signup-Dialogue Page */
.cover:has([class*='EntryPage']) [class*='blurFixedWrapper'],
.cover:has([class*='EntryPage']) [class*='blurFixedWrapperNeoPbx'] {
	display: none;
}
